import { render, staticRenderFns } from "./Explanation3.vue?vue&type=template&id=fd2f2ff4&scoped=true&lang=pug&"
import script from "./Explanation3.vue?vue&type=script&lang=ts&"
export * from "./Explanation3.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2f2ff4",
  null
  
)

export default component.exports